import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useData } from '../contexts/dataContext'

import AdminConsole from './adminConsole'

const Header = (props) => {
    const {currentDate, mostRecentPubDate, setCurrentDate} = useData()

    const headerFS = window.innerWidth < 750 ? 48 : 64

    const getDateString = (date) => {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    const updateCurrentDate = (e) => {
        const date = new Date(e.target.value)
        date.setDate(date.getDate() + 1)
        setCurrentDate(date)
    }

    return (
        <header className='pb-4'>
            <Link to='/' className='d-flex p-1 text-decoration-none text-dark fs-5' style={{display: 'contents', position: 'absolute'}}>
                TWN {/*LOGO HERE */}
            </Link>
            <Row>
                <Col className='d-flex justify-content-center text-center p-0 m-0'>
                    <h1 className={'text-dark fw-bold pt-' + (headerFS === 48 ? '4' : '1')} style={{fontSize: headerFS + 'px'}}>True World News</h1>
                </Col>
            </Row>
            <Row className='text-center border-bottom border-2 border-black mb-1'>
                <h1 className='text-dark' style={{fontSize: (3 * headerFS / 4) + 'px'}}>Where Truth Matters</h1>
            </Row>
            <Row className='text-center border-top border-2 border-black justify-content-center'>
                {currentDate && !props.article ? 
                    <Col lg={2}>
                        <Form.Control 
                            type='date'
                            defaultValue={getDateString(currentDate)}
                            onChange={updateCurrentDate}
                            min={'2024-04-20'}
                            max={getDateString(mostRecentPubDate)}
                            className='text-center py-0 mt-1 fw-bold fs-4'
                        />
                    </Col>
                :
                    <h4 className='mt-1 fw-bold'>{(new Date().toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))}</h4>
                }
            </Row>
            {/* <Row>
                <AdminConsole />
            </Row> */}
        </header>
    )
}

export default Header