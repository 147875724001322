import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Section from './section'
import Column from './column'
import Headlines from './headlines'
import { useData } from '../contexts/dataContext'
import Header from './header'
import paper from '../assets/img/paper.png'

const Home = () => {
    const { articleData, sections } = useData()

    const [headlinesData, setHeadlinesData] = useState([])
    const [columnData, setColumnData] = useState([])
    const [sectionData, setSectionData] = useState({})

    const mobile = window.innerWidth < 750

    useEffect(() => {
        const distributeData = () => {
            let newSectionData = {}
            if ( articleData ) {
                sections.forEach(section => newSectionData[section.name] = [])
                articleData.forEach(article => article.type !== 'headline' && newSectionData[article.type].push(article))
                setSectionData(newSectionData)

                const loadedHeadlines = articleData.filter(article => article.type === 'headline')
                if (loadedHeadlines.length < 5) {
                    if (newSectionData['Business'].length)
                        loadedHeadlines.push(newSectionData['Business'][newSectionData['Business'].length - 1])
                    if (newSectionData['Lifestyle'].length)
                        loadedHeadlines.push(newSectionData['Lifestyle'][newSectionData['Lifestyle'].length - 1])
                    if (newSectionData['Sports'].length)
                        loadedHeadlines.push(newSectionData['Sports'][newSectionData['Sports'].length - 1])
                    if (newSectionData['Business'].length)
                        loadedHeadlines.push(newSectionData['Business'][newSectionData['Business'].length - 2])
                    if (newSectionData['Politics'].length)
                        loadedHeadlines.push(newSectionData['Politics'][newSectionData['Politics'].length - 1])
                }
                setHeadlinesData(loadedHeadlines.slice(0, 5))
                setColumnData(loadedHeadlines.slice(5))
            }
        }
        distributeData()
    }, [articleData, sections])

    return (
        <section className='p-1' style={{backgroundImage: `url(${paper})`, backgroundAttachment: 'fixed', overflowX: 'hidden'}}>
            <Header />
            {headlinesData && headlinesData.length ?
                <Container fluid>
                    <Row><h1 className='mb-2 pb-2 fw-bold text-center'>HEADLINES</h1></Row>
                    <Row className='d-flex flex-row justify-content-center w-100'>
                        {mobile ? 
                            <Headlines data={headlinesData} /> 
                        : 
                            <>
                            {columnData.length ?
                                <Col className='d-flex' lg={2}>
                                    <Column data={columnData} />
                                </Col>
                            :
                                <></>
                            }
                                <Col className='d-flex justify-content-center w-100'>
                                    <Headlines data={headlinesData} />
                                </Col>
                            </>
                        }
                    </Row>
                </Container>
            :
                <h1 className='text-center'>No Headlines Today</h1>
            }
            <h1 className='pt-4 mb-0 fw-bold text-center'>SECTIONS</h1>
            {sections.map((section, index) => (
                sectionData[section.name] && sectionData[section.name].length ? 
                    <Section section={section.name} key={index} data={sectionData[section.name]} />
                :
                    <h3 key={index} className='text-center'>No {section.name} Articles Today</h3>
            ))}
        </section>
    )
}

export default Home