import React from 'react'

import { Container } from 'react-bootstrap'
import Header from './header'
import paper from '../assets/img/paper.jpg'
import { Link } from 'react-router-dom'

const NoPage = () => {
    return (
        <section style={{backgroundImage: `url(${paper})`, backgroundAttachment: 'fixed', overflowX: 'hidden', height: '100vh'}}>
            {window.innerWidth < 750 ? <h1 className='text-center fw-bold py-4' style={{fontSize: '48px'}}>True World News</h1> : <Header />} 
            <Container className='border rounded border-3 border-dark mb-3 text-center'>
                <h1 className='fw-bold py-5' style={{fontSize: (window.innerWidth < 750 ? '48px': '64px')}}>Article Not Found</h1>
                <Link to='/' className='text-decoration-none fs-3'>Click here to go to Today's Publication</Link>
            </Container>
        </section>
    )
}

export default NoPage