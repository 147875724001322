import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ArticleCard from './common/articleCard'

const Headlines = (props) => {
    const mobile = window.innerWidth < 750

    return (
        <Container className='px-0 mx-0'>
            <Row className={'d-flex ' + (mobile ? 'flex-column pe-0' : '')}>
                <Col className={'d-flex mb-2 pe-0 ' + (mobile ?  '' : 'w-50')}>
                    <ArticleCard data={props.data[0]} />
                </Col>
                <Col className={'d-flex mb-2 pe-0 ' + (mobile ? '' : 'w-50')}>
                    <ArticleCard data={props.data[1]} />
                </Col>
            </Row>
            {props.data.slice(2).map( (article, index) => (
                <Row key={index} className='mb-2'>
                    <Col className='w-100 pe-0'>
                        <ArticleCard data={article} />
                    </Col>
                </Row>
            ))}
        </Container>
    )
}

export default Headlines