import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import ArticleCard from './common/articleCard'

const Section = (props) => {
    return (
        <Container fluid>
            <Row className='w-100 ps-2 pe-0 py-0'>
                <Col><h3 className='fw-bold'>{props.section}</h3></Col>
                <Col className='text-end pe-0'><ArrowRightCircle className='mt-1' style={{fontSize: '20px'}}/></Col>
            </Row>
            <Row className='d-flex flex-row flex-nowrap justify-content-between border-dark border-2 border-top border-bottom p-1 overflow-x-auto'>
                    { props.data.map((article, index) => (
                        <Col key={index} className={'my-2 ' + (index > 0 ? 'border-start border-2' : '')} style={{minWidth: '400px'}}>
                            <ArticleCard data={article} />
                        </Col>
                    ))}
            </Row>
        </Container>
    )
}

export default Section