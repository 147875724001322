import React from 'react'
import { Card, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Column = (props) => {
    return (
        <Card className='mb-1 bg-transparent border-3'>
            <Card.Body style={{height: '80vh', maxWidth: '30vw', overflowY: 'auto', direction: 'rtl'}} className='d-flex flex-column justify-content-between'>
                { props.data.map( (article, index) => (
                    <Link key={index} to={'/article/' + article.id} className='text-decoration-none text-dark'>
                        <Row className='text-center fw-bold pb-1'>
                            {article.title}
                        </Row>
                    </Link>
                ))}
            </Card.Body>
        </Card>
    )
}

export default Column