import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { collection, getDocs, doc, deleteDoc, Timestamp, query, orderBy, limit, where } from 'firebase/firestore'

import { db } from "../config/firebase";

const DataContext = createContext()

export function useData() {
    return useContext( DataContext )
}

export function DataProvider( { children } ) {
    const [loading, setLoading] = useState(true)
    const [articleData, setArticleData] = useState()

    const [currentDate, setCurrentDate] = useState()
    const [mostRecentPubDate, setMostRecentPubDate] = useState()

    const sections = [ 
        {name: 'Business', keywords: ['business', 'finance', 'economics', 'company', 'money']}, 
        {name: 'Politics', keywords: ['politics', 'president', 'government', 'politician', 'election', 'republican', 'democrat']}, 
        {name: 'Technology', keywords: ['technology', 'app', 'software', 'startup', 'invention', 'tool', 'computer']}, 
        {name: 'Lifestyle', keywords: ['lifestyle', 'family', 'fashion', 'trend', 'people', 'relationship']},
        {name: 'Sports', keywords: ['sports', 'games', 'basketball', 'baseball', 'soccer', 'football', 'golf']}
    ]

    async function cleanDatabase() {
        const docsRef = await getDocs( collection( db, 'articles'))
        docsRef.forEach(async docSnap => {
            await deleteDoc( doc( db, 'articles', docSnap.id ))
        })
    }

    async function removeBadArticles() {
        const docsRef = await getDocs( collection( db, 'articles'))
        docsRef.forEach(async docSnap => {
            const data = docSnap.data()
            if (data.title.toLowerCase().includes('accept all') || data.title.toLowerCase().includes('cookies') || data.title.toLowerCase().includes('please provide the content'))
                await deleteDoc( doc( db, 'articles', docSnap.id ))
        })
    }

    async function deleteMostRecentPublication() {
        const docsRef = await getDocs( collection( db, 'articles'))
        docsRef.forEach(async docSnap => {
            if (docSnap.data().published.toDate().toDateString() === new Date().toDateString())
            await deleteDoc( doc( db, 'articles', docSnap.id ))
        })
    }

    const updateCurrentDate = useCallback(async () => {
        const currDateQuery = query(collection(db, 'articles'), orderBy('published', 'desc'), limit(1))
        const latestArticle = await getDocs(currDateQuery)
        const latestArticleDate = latestArticle.docs[0].data().published.toDate()
        
        setMostRecentPubDate(latestArticleDate)
        setCurrentDate(latestArticleDate)
    }, [])

    const fetchData = useCallback(async () => {
        try {
            if (!currentDate) {
                throw new Error("No Date Found")
            }
    
            const currDateStart = new Date(`${currentDate.getUTCFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}T00:00:00Z`)
            const currDateEnd = new Date(`${currentDate.getUTCFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}T23:59:59Z`)
            const dateQuery = query(collection(db, 'articles'), where('published', '>=', Timestamp.fromDate(currDateStart)), where('published', '<=', Timestamp.fromDate(currDateEnd)))
            const articlesSnapshot = await getDocs(dateQuery)

            let taggedData = []
            articlesSnapshot.forEach(doc => taggedData.push({...(doc.data()), id: doc.id}))

            let doctoredData = []
            for ( const article of taggedData ) {
                let title = article.title.charAt(0) === ':' ? article.title.substring(1).trim() : article.title
                let desc = article.description.charAt(0) === ':' ? article.description.substring(1).trim() : article.description
                
                title = title.replaceAll('*', '').replaceAll('"', '')
                doctoredData.push({...article, title: title, description: desc})
            }

            setArticleData(doctoredData)
        } catch( err ) {
            console.log('Failed to load article data: ' + err.message)
        }
    }, [currentDate])

    useEffect(() => {
        setLoading(true)
        if (!currentDate) {
            updateCurrentDate()
        } else {
            fetchData()
        }
        setLoading(false)
    }, [updateCurrentDate, fetchData, currentDate])

    const value = {
        cleanDatabase,
        deleteMostRecentPublication,
        setCurrentDate,
        currentDate,
        mostRecentPubDate,
        articleData,
        sections,
        removeBadArticles
    }

    return (
        <DataContext.Provider value={value}>
            {!loading && children }
        </DataContext.Provider>
    )
}