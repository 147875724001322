import React from 'react';
import { DataProvider } from './contexts/dataContext';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Home from './components/home'
import Article from './components/article'
import NoPage from './components/noPage';

function App() {
    return (
        <DataProvider>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Outlet />}>
                        <Route index element={<Home />} />
                        <Route path={'/article/:id'} element={<Article />} />
                        <Route path='*' element={<NoPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </DataProvider>
    );
}

export default App;
