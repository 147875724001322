import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './articleCard.css'

const ArticleCard = (props) => {
    const mobile = window.innerWidth < 750
    const titleFS = (mobile ? 14 : 18)
    const textFS = (mobile ? 12 : 16)

    return (
        <Link to={'/article/' + props.data.id} className='text-decoration-none'>
            <Card className='mb-1 bg-transparent border-0 w-100 h-100'>
                <Card.Header className='bg-transparent'>
                    <Card.Title className='articleCardText fw-bold' style={{fontSize: titleFS + 'px', '--lines': 2}}>{props.data.title}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text className='articleCardText' style={{fontSize: textFS + 'px', '--lines': 8}}>
                        {props.data.description}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Link>
    )
}

export default ArticleCard