import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useData } from '../contexts/dataContext'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import Header from './header'
import paper from '../assets/img/paper.png'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from 'react-share'
import { ShareFill } from 'react-bootstrap-icons'

const Article = () => {
    const { articleData } = useData()
    const articleID = useParams().id
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState()

    const [showShareModal, setShowShareModal] = useState(false)

    const shareText = 'I just saw this article on TWN - Check it out! '
    const mobile = window.innerWidth < 750

    useEffect(() => {
        if ( articleData ) {
            const data = articleData.find(article => article.id === articleID)
            if ( !data ) {
                navigate('/articleNotFound')
            }
            setPageData(data)
            setLoading(false)
        }
    }, [articleData, articleID, navigate])

    return (
        !loading &&
        <section className='p-1' style={{backgroundImage: `url(${paper})`, backgroundAttachment: 'fixed', overflowX: 'hidden'}}>
            {mobile ? <Link to='/' className='text-decoration-none text-dark'><h1 className='text-center fw-bold py-4' style={{fontSize: '48px'}}>True World News</h1></Link> : <Header article/>} 
            <Container fluid className='border rounded border-3 border-dark mb-3'>
                <header className='text-center pt-4 pb-2'> 
                    <h1 className='fw-bold' style={{fontSize: (mobile ? '32px' : '64px')}}>{pageData.title}</h1>
                    <Row className={'d-flex justify-content-between ' + (mobile ? 'flex-column' : 'flex-row')}>
                        <Col className={'text-nowrap ' + (mobile ? 'pt-2 text-center' : 'pt-1 text-start ps-5')}>
                            <h5>Source: {pageData.author}.</h5>
                            <h5>True'd up by TWN on {pageData.published.toDate().toLocaleDateString()}</h5>
                        </Col>
                        <Col className={'py-2 ' + (mobile ? 'd-flex flex-row justify-content-center' : 'd-flex flex-row justify-content-end pe-5')}>
                            <Button onClick={() => setShowShareModal(true)} className='bg-transparent border-0 text-dark d-flex flex-row'>
                                <ShareFill className='mt-1 pe-1 fs-4'/>
                                <h4>Share</h4>
                            </Button>
                            <Modal show={showShareModal} onHide={() => setShowShareModal(false)}>
                                <Modal.Header closeButton>Share Article</Modal.Header>
                                <Modal.Body>
                                    <Form.Control as='textarea' rows={2} readOnly defaultValue={shareText + '\n' + window.location.href} className='text-center'/>
                                </Modal.Body>
                                <Modal.Footer className='d-flex flex-row justify-content-center'>
                                    <FacebookShareButton url={window.location.href} quote={shareText} hashtag='#TWN' className='p-1'>
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <FacebookMessengerShareButton url={window.location.href} quote={shareText} hashtag='#TWN' className='p-1'>
                                        <FacebookMessengerIcon size={32} round />
                                    </FacebookMessengerShareButton>
                                    <TwitterShareButton url={window.location.href} quote={shareText} hashtag='#TWN' className='p-1'>
                                        <XIcon size={32} round />
                                    </TwitterShareButton>
                                    <LinkedinShareButton url={window.location.href} quote={shareText} hashtag='#TWN' className='p-1'>
                                        <LinkedinIcon size={32} round />
                                    </LinkedinShareButton>
                                    <WhatsappShareButton url={window.location.href} quote={shareText} hashtag='#TWN' className='p-1'>
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                    <TelegramShareButton url={window.location.href} quote={shareText} hashtag='#TWN' className='p-1'>
                                        <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                    <EmailShareButton url={window.location.href} quote={shareText} hashtag='#TWN' className='p-1'>
                                        <EmailIcon size={32} round />
                                    </EmailShareButton>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                </header>
                <div className='text-center'>
                    <h2 className='text-decoration-underline'>At a Glance</h2>
                    <h3 style={{fontSize: (mobile ? '20px' : '')}}>{pageData.description}</h3><br/>
                    <h2 className='text-decoration-underline'>Full Article</h2>
                    {pageData.content.includes("---") ? 
                        pageData.content.split('---').map((sentence, index) => (
                            <h2 key={index} className='py-1' style={{fontSize: (mobile ? '16px' : '')}}>{sentence}</h2>
                        ))
                    : 
                        pageData.content.split('. ').map((sentence, index) => (
                            <h2 key={index} className='py-1' style={{fontSize: (mobile ? '16px' : '')}}>{sentence + (index === pageData.content.split('. ').length - 1 ? '' : '.')}</h2>
                        ))
                    }
                </div>
            </Container>
        </section>
    )
}

export default Article
